import moment from 'moment';

import { Table } from '@/components/data-display/Table';
import Text from '@/components/data-display/Text';
import UserAvatar from '@/components/data-display/UserAvatar';

import FullButton from '@/components/data-entry/FullButton';

import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { NoResultsMessage } from '@/components/feedback/NoResultsMessage';

import { usePatientDashboardQuery } from '@/generated/graphql';

import { patientApptRoomHref } from '@/lib/constants';

import { handleError } from '@/utils/errors';
import { getUserNameVariants } from '@/utils/getUserNameVariants';
import { throttledGetTimeXHoursAgo } from '@/utils/time';

import { wrapWithErrorBoundary } from '../feedback/utils/wrapWithErrorBoundary';

const takeItems = 16;

export const PatientDashboardUpcoming = () => {
  const twoHoursAgoISO = throttledGetTimeXHoursAgo(2);

  const { data, loading, fetchMore } = usePatientDashboardQuery({
    notifyOnNetworkStatusChange: true,
    variables: { startsAt: { gt: twoHoursAgoISO }, take: takeItems },
  });

  const upcomingBookings = data?.authenticatedItem?.patient?.bookings || [];

  const loadMoreOptions = {
    dataItemsPath: 'authenticatedItem.patient.bookings',
    fetcher: fetchMore,
    queryVariables: { take: takeItems },
    resultsLength: upcomingBookings.length,
  };

  return (
    <>
      {!upcomingBookings?.length && !loading && (
        <NoResultsMessage message="You have no more appointments." />
      )}

      {loading && upcomingBookings?.length <= 0 && <LoadingSpinner />}

      {upcomingBookings?.length > 0 && (
        <Table
          onReachEnd={{
            loadMoreOptions,
          }}
        >
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Doctor</Table.HeadCell>
              <Table.HeadCell>Type</Table.HeadCell>
              <Table.HeadCell>Time</Table.HeadCell>
              <Table.HeadCell className="pr-5 sm:pr-7 text-right">
                Action
              </Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {upcomingBookings.map(booking => {
              const doctorUser = booking?.doctor?.user;
              const apptId = booking?.appointment?.id;

              const { firstName: doctorFirstName, fullName: doctorFullName } =
                getUserNameVariants(doctorUser);

              const { id: bookingID, startsAt, event } = booking;

              const eventType = event?.eventType?.label;
              const eventTypeValue = event?.eventType?.value;

              const startsAtTime = moment(startsAt).format('h:mm a');

              if (!apptId) {
                handleError(`Appointment has no doctor, [appt.id]: ${apptId}`);
                return null;
              }
              if (!doctorUser) {
                handleError(`Appointment has no doctor, [appt.id]: ${apptId}`);
                return null;
              }
              if (!bookingID) {
                handleError(`Appointment has no booking, [appt.id]: ${apptId}`);
                return null;
              }

              return (
                <Table.Row key={apptId}>
                  <Table.Cell className="font-medium">
                    {!doctorUser && 'Unknown Doctor'}
                    {doctorUser && (
                      <div className="flex flex-row gap-2 items-center">
                        <UserAvatar size="30px" user={doctorUser} />
                        <Text className="ellipsis" title={doctorFullName}>
                          {doctorFirstName}
                        </Text>
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Text className="ellipsis" title={eventType as string}>
                      {eventType}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text className="ellipsis" title={startsAtTime}>
                      {startsAtTime}
                    </Text>
                  </Table.Cell>
                  <Table.Cell className="pr-4 text-right font-medium sm:pr-6 min-w-[200px] max-w-[220px]">
                    {eventTypeValue === 'virtual' && (
                      <FullButton
                        data-testid={`btn-join-${eventType}-appt`}
                        size="sm"
                        variant="outline"
                        href={`${patientApptRoomHref}/${apptId}`}
                      >
                        Join
                      </FullButton>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

export const PatientDashboardUpcomingWithBoundary = wrapWithErrorBoundary(
  PatientDashboardUpcoming
);
